<template>
  <div class="home">
    <v-card
        class="mx-auto"
        color="primary"
        dark
    >
      <v-card-title>
        <v-icon
            large
            left
        >
          mdi-heart
        </v-icon>
        <span class="title font-weight-light">Wisdom</span>
      </v-card-title>

      <v-card-text class="headline font-weight-bold">
        All right,I'd got to work as hard as I possibly can,and see what happens
      </v-card-text>


    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  mounted() {
    if (!window._initHome) {
      this.$router.push('/form');
      window._initHome = true;

    }
  },
};
</script>
